import { useState, useEffect, useCallback } from "react"
/** @jsx jsx */
import { css, jsx } from "@emotion/core"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { AnimatePresence, motion } from "framer-motion"

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */

export const imageFragment = graphql`
  fragment imageFragment on File {
    childImageSharp {
      fluid(maxWidth: 3000) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`
const Image = () => {
  const data = useStaticQuery(graphql`
    query {
      frame1: file(relativePath: { eq: "backgrounds/la-ginestra-01.jpg" }) {
        ...imageFragment
      }
      frame2: file(relativePath: { eq: "backgrounds/la-ginestra-02.jpg" }) {
        ...imageFragment
      }
      frame3: file(relativePath: { eq: "backgrounds/la-ginestra-03.jpg" }) {
        ...imageFragment
      }
      frame4: file(relativePath: { eq: "backgrounds/la-ginestra-04.jpg" }) {
        ...imageFragment
      }
      frame5: file(relativePath: { eq: "backgrounds/la-ginestra-05.jpg" }) {
        ...imageFragment
      }
      frame6: file(relativePath: { eq: "backgrounds/lg-01.jpg" }) {
        ...imageFragment
      }
      frame7: file(relativePath: { eq: "backgrounds/lg-02.jpg" }) {
        ...imageFragment
      }
      frame8: file(relativePath: { eq: "backgrounds/lg-03.jpg" }) {
        ...imageFragment
      }
      frame9: file(relativePath: { eq: "backgrounds/lg-04.jpg" }) {
        ...imageFragment
      }
      frame10: file(relativePath: { eq: "backgrounds/lg-05.jpg" }) {
        ...imageFragment
      }
      frame11: file(relativePath: { eq: "backgrounds/lg-06.jpg" }) {
        ...imageFragment
      }
      frame12: file(relativePath: { eq: "backgrounds/lg-07.jpg" }) {
        ...imageFragment
      }
      frame13: file(relativePath: { eq: "backgrounds/lg-08.jpg" }) {
        ...imageFragment
      }
      frame14: file(relativePath: { eq: "backgrounds/lg-09.jpg" }) {
        ...imageFragment
      }
      frame15: file(relativePath: { eq: "backgrounds/lg-10.jpg" }) {
        ...imageFragment
      }
      frame16: file(relativePath: { eq: "backgrounds/lg-11.jpg" }) {
        ...imageFragment
      }
      frame17: file(relativePath: { eq: "backgrounds/lg-12.jpg" }) {
        ...imageFragment
      }
      frame18: file(relativePath: { eq: "backgrounds/lg-13.jpg" }) {
        ...imageFragment
      }
      frame19: file(relativePath: { eq: "backgrounds/lg-14.jpg" }) {
        ...imageFragment
      }
      frame20: file(relativePath: { eq: "backgrounds/lg-15.jpg" }) {
        ...imageFragment
      }
    }
  `)
  const [frames, setFrames] = useState([])
  useEffect(() => {
    const randomized = []
    const keys = Object.keys(data)
    keys.forEach(key => {
      while (1) {
        const rand = keys[Math.floor(Math.random() * keys.length)]
        if (randomized.indexOf(rand) === -1) {
          randomized.push(rand)
          break
        }
      }
    })
    setFrames(randomized.map(k => data[k]))
  }, [data])

  const [initialised, setInitialized] = useState(false)
  const [pos, setPos] = useState(0)
  const incrementPos = useCallback(() => {
    if (!frames.length) return
    setPos((pos + 1) % frames.length)
  }, [pos, frames.length])

  useEffect(() => {
    if (!initialised) {
      incrementPos()
    } else {
      let interval = setInterval(incrementPos, 17000)
      return () => clearInterval(interval)
    }
  }, [incrementPos, initialised])

  useEffect(() => {
    setInitialized(true)
  }, [setInitialized])
  if (!frames.length) return null

  return (
    <div
      css={css`
        width: 100vw;
        height: 100vh;
        position: relative;
      `}
    >
      <AnimatePresence>
        <motion.div
          key={pos}
          transition={{ duration: initialised ? 17 : 0.1 }}
          initial={{ opacity: 0, scale: 1.2 }}
          exit={{ opacity: 0, scale: 1.2 }}
          animate={{ opacity: 1, scale: 1 }}
          css={css`
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
          `}
        >
          <Img
            css={css`
              display: block;
              width: 100vw;
              height: 100vh;
            `}
            key={pos}
            fluid={frames[pos].childImageSharp.fluid}
          />
        </motion.div>
      </AnimatePresence>
    </div>
  )
}

export default Image
